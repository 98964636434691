import { supabase } from "@/integrations/supabase/client";

// Type definitions
export interface ShortUrl {
  id: string;
  originalUrl: string;
  shortCode: string;
  shortUrl: string;
  createdAt: string;
  clicks: number;
  lastClickedAt: string | null;
}

export interface ClickData {
  timestamp: string;
  referrer: string | null;
  userAgent: string;
}

// Generate a random short code
const generateShortCode = (): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const length = 6;
  
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  
  return result;
};

// Validate URL format
export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// Get all shortened URLs for the current user
export const getShortUrls = async (): Promise<ShortUrl[]> => {
  // First try to get from Supabase if user is logged in
  const session = await supabase.auth.getSession();
  
  if (session.data.session) {
    try {
      const { data, error } = await supabase
        .from('short_urls')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (error) throw error;
      
      return data.map(item => ({
        id: item.id,
        originalUrl: item.original_url,
        shortCode: item.short_code,
        shortUrl: `towr.co/${item.short_code}`,
        createdAt: item.created_at,
        clicks: item.clicks,
        lastClickedAt: item.last_clicked_at
      }));
    } catch (error) {
      console.error('Error fetching URLs from Supabase:', error);
      // Fall back to local storage
    }
  }
  
  // Fall back to local storage for anonymous users
  const storedUrls = localStorage.getItem('towrco_urls');
  return storedUrls ? JSON.parse(storedUrls) : [];
};

// Get a single shortened URL by its code
export const getShortUrlByCode = async (code: string): Promise<ShortUrl | undefined> => {
  // Try to get from Supabase first
  try {
    const { data, error } = await supabase
      .from('short_urls')
      .select('*')
      .eq('short_code', code)
      .single();
      
    if (error) {
      if (error.code !== 'PGRST116') { // PGRST116 is "No rows returned" error
        console.error('Error fetching URL from Supabase:', error);
      }
    } else {
      return {
        id: data.id,
        originalUrl: data.original_url,
        shortCode: data.short_code,
        shortUrl: `towr.co/${data.short_code}`,
        createdAt: data.created_at,
        clicks: data.clicks,
        lastClickedAt: data.last_clicked_at
      };
    }
  } catch (error) {
    console.error('Error in getShortUrlByCode:', error);
  }
  
  // Fall back to local storage
  const urls = await getLocalShortUrls();
  return urls.find(url => url.shortCode === code);
};

// Helper function to get URLs from local storage
const getLocalShortUrls = (): ShortUrl[] => {
  const storedUrls = localStorage.getItem('towrco_urls');
  return storedUrls ? JSON.parse(storedUrls) : [];
};

// Create a new shortened URL
export const createShortUrl = async (originalUrl: string): Promise<ShortUrl> => {
  if (!isValidUrl(originalUrl)) {
    throw new Error('Invalid URL format');
  }
  
  // Normalize the URL (add https:// if missing)
  if (!originalUrl.startsWith('http://') && !originalUrl.startsWith('https://')) {
    originalUrl = `https://${originalUrl}`;
  }
  
  const session = await supabase.auth.getSession();
  const isAuthenticated = !!session.data.session;
  
  // Generate a unique short code
  let shortCode = generateShortCode();
  
  // Check for existing URLs in both local storage and Supabase
  const existingUrls = await getShortUrls();
  
  // Check if URL has already been shortened
  const existingUrl = existingUrls.find(url => url.originalUrl === originalUrl);
  if (existingUrl) {
    return existingUrl;
  }
  
  // Ensure short code is unique
  while (existingUrls.some(url => url.shortCode === shortCode)) {
    shortCode = generateShortCode();
  }
  
  // Create new shortened URL object with production domain
  const timestamp = new Date().toISOString();
  const newShortUrl: ShortUrl = {
    id: crypto.randomUUID(),
    originalUrl,
    shortCode,
    shortUrl: `https://towr.co/${shortCode}`,
    createdAt: timestamp,
    clicks: 0,
    lastClickedAt: null
  };
  
  if (isAuthenticated) {
    // Save to Supabase if user is authenticated
    try {
      const { data, error } = await supabase
        .from('short_urls')
        .insert({
          id: newShortUrl.id,
          user_id: session.data.session.user.id,
          original_url: originalUrl,
          short_code: shortCode,
          created_at: timestamp,
          clicks: 0,
          last_clicked_at: null
        })
        .select()
        .single();
        
      if (error) throw error;
      
      return {
        id: data.id,
        originalUrl: data.original_url,
        shortCode: data.short_code,
        shortUrl: `towr.co/${data.short_code}`,
        createdAt: data.created_at,
        clicks: data.clicks,
        lastClickedAt: data.last_clicked_at
      };
    } catch (error) {
      console.error('Error saving URL to Supabase:', error);
      // Fall back to local storage
    }
  }
  
  // Save to local storage for anonymous users or as fallback
  const localUrls = getLocalShortUrls();
  localStorage.setItem('towrco_urls', JSON.stringify([newShortUrl, ...localUrls]));
  
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 300));
  
  return newShortUrl;
};

// Record a click on a shortened URL
export const recordClick = async (shortCode: string): Promise<void> => {
  try {
    // Try to update in Supabase first
    const { data, error } = await supabase
      .from('short_urls')
      .select('*')
      .eq('short_code', shortCode)
      .single();
      
    if (!error) {
      await supabase
        .from('short_urls')
        .update({
          clicks: data.clicks + 1,
          last_clicked_at: new Date().toISOString()
        })
        .eq('id', data.id);
    } else {
      // Fall back to local storage
      const urls = getLocalShortUrls();
      const urlIndex = urls.findIndex(url => url.shortCode === shortCode);
      
      if (urlIndex !== -1) {
        // Update click count and timestamp
        urls[urlIndex].clicks += 1;
        urls[urlIndex].lastClickedAt = new Date().toISOString();
        
        // Save click data
        const clickData: ClickData = {
          timestamp: new Date().toISOString(),
          referrer: document.referrer || null,
          userAgent: navigator.userAgent
        };
        
        // Store the click data
        const clicksStorageKey = `towrco_clicks_${shortCode}`;
        const storedClicks = localStorage.getItem(clicksStorageKey);
        const clicks = storedClicks ? JSON.parse(storedClicks) : [];
        localStorage.setItem(clicksStorageKey, JSON.stringify([...clicks, clickData]));
        
        // Update the URLs in storage
        localStorage.setItem('towrco_urls', JSON.stringify(urls));
      }
    }
  } catch (error) {
    console.error('Error recording click:', error);
  }
};

// Redirect to the original URL and record the click
export const handleRedirect = async (shortCode: string): Promise<string | null> => {
  const shortUrl = await getShortUrlByCode(shortCode);
  
  if (shortUrl) {
    await recordClick(shortCode);
    return shortUrl.originalUrl;
  }
  
  return null;
};

// Delete a shortened URL
export const deleteShortUrl = async (id: string): Promise<void> => {
  const session = await supabase.auth.getSession();
  const isAuthenticated = !!session.data.session;
  
  if (isAuthenticated) {
    try {
      const { error } = await supabase
        .from('short_urls')
        .delete()
        .eq('id', id);
        
      if (error) throw error;
    } catch (error) {
      console.error('Error deleting URL from Supabase:', error);
      // Fall back to local storage
    }
  }
  
  // Also remove from local storage
  const urls = getLocalShortUrls();
  const filteredUrls = urls.filter(url => url.id !== id);
  localStorage.setItem('towrco_urls', JSON.stringify(filteredUrls));
};
