// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://zyndbneqhpnkelefcedy.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp5bmRibmVxaHBua2VsZWZjZWR5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDIyNDkzMzEsImV4cCI6MjA1NzgyNTMzMX0.dLl-Ls36Ng9fOb6oVFsns0A12f6e4wWDsTplO-Yf7ZA";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);