
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShortUrl, getShortUrls } from '@/lib/shortenerService';
import { UrlCard } from './UrlCard';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { LogIn } from 'lucide-react';

interface UrlListProps {
  newUrl: ShortUrl | null;
}

export const UrlList = ({ newUrl }: UrlListProps) => {
  const [urls, setUrls] = useState<ShortUrl[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  // Load URLs from Supabase or local storage on mount
  useEffect(() => {
    const loadUrls = async () => {
      setIsLoading(true);
      try {
        const storedUrls = await getShortUrls();
        setUrls(storedUrls);
      } catch (error) {
        console.error('Error loading URLs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUrls();
  }, [user]); // Reload when user changes

  // Update URLs when a new one is created
  useEffect(() => {
    if (newUrl) {
      setUrls(prev => {
        // Check if the URL already exists in the list
        const exists = prev.some(url => url.id === newUrl.id);
        if (exists) {
          return prev;
        }
        return [newUrl, ...prev];
      });
    }
  }, [newUrl]);

  const handleDelete = (id: string) => {
    setUrls(prev => prev.filter(url => url.id !== id));
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  if (isLoading) {
    return (
      <div className="w-full max-w-3xl mx-auto mt-8 p-8 animate-pulse">
        <div className="h-16 bg-gray-200 rounded-lg mb-4"></div>
        <div className="h-16 bg-gray-200 rounded-lg mb-4"></div>
        <div className="h-16 bg-gray-200 rounded-lg"></div>
      </div>
    );
  }

  if (urls.length === 0) {
    return (
      <div className="w-full max-w-3xl mx-auto mt-8 text-center p-8 glass-card">
        <h3 className="text-lg font-medium mb-2">No shortened URLs yet</h3>
        <p className="text-muted-foreground">
          Your shortened URLs will appear here after you create them.
        </p>
        
        {!user && urls.length === 0 && (
          <div className="mt-6">
            <Button 
              variant="outline" 
              onClick={handleSignIn}
              className="text-tower-600 border-tower-200 hover:bg-tower-50"
            >
              <LogIn size={16} className="mr-2" />
              Sign in to save your URLs
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full max-w-3xl mx-auto mt-8">
      <h2 className="text-xl font-semibold mb-6">Your shortened URLs</h2>
      <ScrollArea className="max-h-[500px] pr-4 -mr-4">
        <div className="space-y-4">
          {urls.map(url => (
            <div 
              key={url.id} 
              className="animate-scale-in" 
              style={{ 
                animationDelay: `${urls.findIndex(u => u.id === url.id) * 0.05}s`,
                animationFillMode: 'backwards'
              }}
            >
              <UrlCard url={url} onDelete={handleDelete} />
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default UrlList;
