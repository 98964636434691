
import { Link } from 'react-router-dom';
import { Link2 } from 'lucide-react';

export const Footer = () => {
  return (
    <footer className="bg-background py-12 mt-20 border-t border-border/50">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div className="col-span-1 md:col-span-1">
            <Link to="/" className="flex items-center space-x-3 mb-4">
              <div className="w-8 h-8 bg-gradient-to-tr from-tower-600 to-tower-400 rounded-lg flex items-center justify-center shadow-sm">
                <Link2 size={16} className="text-white" />
              </div>
              <span className="font-semibold text-xl tracking-tight">
                towr.co
              </span>
            </Link>
            <p className="text-muted-foreground text-sm">
              Simplify your links, amplify your reach.
            </p>
          </div>

          <div>
            <h3 className="font-medium mb-4">Product</h3>
            <ul className="space-y-3">
              <FooterLink>Features</FooterLink>
              <FooterLink>Integrations</FooterLink>
              <FooterLink>API</FooterLink>
              <FooterLink>Pricing</FooterLink>
            </ul>
          </div>

          <div>
            <h3 className="font-medium mb-4">Resources</h3>
            <ul className="space-y-3">
              <FooterLink>Documentation</FooterLink>
              <FooterLink>Blog</FooterLink>
              <FooterLink>Status</FooterLink>
              <FooterLink>Help Center</FooterLink>
            </ul>
          </div>

          <div>
            <h3 className="font-medium mb-4">Company</h3>
            <ul className="space-y-3">
              <FooterLink>About</FooterLink>
              <FooterLink>Careers</FooterLink>
              <FooterLink>Contact</FooterLink>
              <FooterLink>Privacy</FooterLink>
            </ul>
          </div>
        </div>

        <div className="pt-8 border-t border-border/50 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-muted-foreground">
            © {new Date().getFullYear()} towr.co. All rights reserved.
          </p>
          <div className="flex space-x-6 mt-4 md:mt-0">
            <SocialLink>Twitter</SocialLink>
            <SocialLink>GitHub</SocialLink>
            <SocialLink>LinkedIn</SocialLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ children }: { children: React.ReactNode }) => (
  <li>
    <a 
      href="#" 
      className="text-muted-foreground text-sm hover:text-foreground transition-colors duration-200"
    >
      {children}
    </a>
  </li>
);

const SocialLink = ({ children }: { children: React.ReactNode }) => (
  <a 
    href="#" 
    className="text-muted-foreground text-sm hover:text-foreground transition-colors duration-200"
  >
    {children}
  </a>
);

export default Footer;
