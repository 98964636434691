
import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { ShortUrl, deleteShortUrl } from '@/lib/shortenerService';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Copy, ExternalLink, BarChart2, Trash } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { LinkAnalytics } from './LinkAnalytics';

interface UrlCardProps {
  url: ShortUrl;
  onDelete: (id: string) => void;
}

const formatDate = (dateString: string | null) => {
  if (!dateString) return 'Never';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { 
    month: 'short', 
    day: 'numeric',
    year: 'numeric',
  });
};

export const UrlCard = ({ url, onDelete }: UrlCardProps) => {
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { toast } = useToast();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`https://${url.shortUrl}`);
      setIsCopied(true);
      
      toast({
        title: "Link copied to clipboard",
        duration: 2000,
      });
      
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      toast({
        title: "Failed to copy",
        description: "Please try again or copy manually",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  const handleDelete = () => {
    deleteShortUrl(url.id);
    onDelete(url.id);
    
    toast({
      title: "Link deleted successfully",
      duration: 3000,
    });
  };

  const openOriginalUrl = () => {
    window.open(`https://${url.originalUrl.replace(/^https?:\/\//, '')}`, '_blank');
  };

  return (
    <Card className="glass-card overflow-hidden mb-4 hover:shadow-hover transition-all duration-300">
      <CardContent className="p-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex-1 min-w-0">
            <h3 className="text-tower-600 font-medium text-lg mb-1 truncate">
              {url.shortUrl}
            </h3>
            <p className="text-sm text-muted-foreground truncate mb-2">
              {url.originalUrl}
            </p>
            <div className="flex items-center text-xs text-muted-foreground">
              <span>Created: {formatDate(url.createdAt)}</span>
              <span className="mx-2">•</span>
              <span>Clicks: {url.clicks}</span>
              {url.clicks > 0 && (
                <>
                  <span className="mx-2">•</span>
                  <span>Last click: {formatDate(url.lastClickedAt)}</span>
                </>
              )}
            </div>
          </div>
          
          <div className="flex gap-2 self-end md:self-center">
            <Button
              variant="outline"
              size="sm"
              className="transition-all duration-300 hover:bg-tower-100 hover:text-tower-700"
              onClick={handleCopy}
            >
              {isCopied ? (
                <span className="flex items-center gap-1 text-green-600">
                  <Copy size={16} />
                  Copied
                </span>
              ) : (
                <span className="flex items-center gap-1">
                  <Copy size={16} />
                  Copy
                </span>
              )}
            </Button>
            
            <Button
              variant="outline"
              size="sm"
              className="transition-all duration-300 hover:bg-tower-100 hover:text-tower-700"
              onClick={openOriginalUrl}
            >
              <ExternalLink size={16} className="mr-1" />
              Open
            </Button>
            
            <Dialog open={analyticsOpen} onOpenChange={setAnalyticsOpen}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="transition-all duration-300 hover:bg-tower-100 hover:text-tower-700"
                >
                  <BarChart2 size={16} className="mr-1" />
                  Stats
                </Button>
              </DialogTrigger>
              <DialogContent className="md:max-w-2xl">
                <DialogHeader>
                  <DialogTitle>Analytics for {url.shortUrl}</DialogTitle>
                </DialogHeader>
                <LinkAnalytics shortCode={url.shortCode} />
              </DialogContent>
            </Dialog>
            
            <Button
              variant="outline"
              size="sm"
              className="transition-all duration-300 hover:bg-destructive/10 hover:text-destructive"
              onClick={handleDelete}
            >
              <Trash size={16} />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UrlCard;
