
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Index from "./pages/Index";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import { handleRedirect } from "./lib/shortenerService";
import { AuthProvider } from "./contexts/AuthContext";

const queryClient = new QueryClient();

const RedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if the path is a shortened URL
    const path = location.pathname.substring(1); // Remove leading slash
    
    if (path && path !== "404" && path !== "auth") {
      // Using async/await to handle the Promise returned by handleRedirect
      const redirectToOriginalUrl = async () => {
        const originalUrl = await handleRedirect(path);
        
        if (originalUrl) {
          // Redirect to the original URL
          window.location.href = originalUrl;
        } else {
          // If no matching short URL is found, navigate to 404
          navigate("/404", { replace: true });
        }
      };
      
      redirectToOriginalUrl();
    }
  }, [location, navigate]);
  
  return null;
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <TooltipProvider>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <RedirectHandler />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
