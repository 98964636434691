
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createShortUrl, isValidUrl, ShortUrl } from '@/lib/shortenerService';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { ArrowRight, Link as LinkIcon, LogIn } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';

interface UrlShortenerProps {
  onUrlCreated: (newUrl: ShortUrl) => void;
}

export const UrlShortener = ({ onUrlCreated }: UrlShortenerProps) => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidInput, setIsValidInput] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUrl(value);
    setIsValidInput(value.trim() !== '' && (value.trim().startsWith('http') || !value.includes('.')));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!url.trim()) {
      toast({
        title: "Please enter a URL",
        variant: "destructive",
        duration: 3000,
      });
      return;
    }

    // Prepare the URL (add https:// if not present)
    let urlToShorten = url.trim();
    if (!urlToShorten.startsWith('http://') && !urlToShorten.startsWith('https://')) {
      urlToShorten = `https://${urlToShorten}`;
    }

    if (!isValidUrl(urlToShorten)) {
      toast({
        title: "Invalid URL format",
        description: "Please enter a valid URL",
        variant: "destructive",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    
    try {
      const shortenedUrl = await createShortUrl(urlToShorten);
      onUrlCreated(shortenedUrl);
      toast({
        title: "URL shortened successfully!",
        description: `Your link is ready: ${shortenedUrl.shortUrl}`,
        duration: 5000,
      });
      setUrl('');
    } catch (error) {
      toast({
        title: "Failed to shorten URL",
        description: error instanceof Error ? error.message : "An unexpected error occurred",
        variant: "destructive",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  return (
    <div className="w-full max-w-3xl mx-auto animate-fade-in">
      <div className="glass-card p-6 md:p-8">
        <h2 className="text-xl font-semibold mb-6 text-center">Shorten your link</h2>
        
        <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-4">
          <div className="relative flex-1">
            <div className="absolute left-3 inset-y-0 flex items-center pointer-events-none text-muted-foreground">
              <LinkIcon size={18} />
            </div>
            <Input
              type="text"
              placeholder="Paste your long URL here..."
              value={url}
              onChange={handleChange}
              className="pl-10 py-6 focus:ring-tower-500 focus:border-tower-500 transition-all duration-300"
              disabled={isLoading}
            />
          </div>
          
          <Button 
            type="submit" 
            className="bg-tower-500 hover:bg-tower-600 transition-all duration-300 py-6 px-8"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center space-x-2">
                <span className="h-4 w-4 rounded-full border-2 border-white border-t-transparent animate-spin"></span>
                <span>Shortening...</span>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <span>Shorten</span>
                <ArrowRight size={18} />
              </div>
            )}
          </Button>
        </form>
        
        {!user && (
          <div className="mt-4 pt-4 border-t border-gray-100 text-center">
            <p className="text-sm text-muted-foreground mb-3">
              Sign in to save and manage your shortened URLs
            </p>
            <Button
              variant="outline"
              size="sm"
              onClick={handleSignIn}
              className="text-tower-600 border-tower-200 hover:bg-tower-50"
            >
              <LogIn size={16} className="mr-2" />
              Sign in or create an account
            </Button>
          </div>
        )}
        
        <p className="text-xs text-muted-foreground mt-4 text-center">
          By using our service, you agree to our Terms of Service and Privacy Policy
        </p>
      </div>
    </div>
  );
};

export default UrlShortener;
