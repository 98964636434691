import { useState } from "react";
import { Header } from "@/components/Header";
import { UrlShortener } from "@/components/UrlShortener";
import { UrlList } from "@/components/UrlList";
import { Footer } from "@/components/Footer";
import { ShortUrl } from "@/lib/shortenerService";
import { Link, ChevronRight, BarChart, Globe, Zap, Lock } from "lucide-react";

const Index = () => {
  const [newUrl, setNewUrl] = useState<ShortUrl | null>(null);

  const handleUrlCreated = (url: ShortUrl) => {
    setNewUrl(url);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-subtle from-white to-blue-50">
      <Header />

      <main className="flex-1 pt-24">
        {/* Hero Section */}
        <section className="container mx-auto px-6 pt-16 pb-24">
          <div className="max-w-4xl mx-auto text-center animate-fade-in">
            <div className="inline-block px-3 py-1 mb-6 rounded-full bg-tower-100 text-tower-700 text-sm font-medium">
              Simplify your links
            </div>
            <h1 className="text-4xl md:text-6xl font-bold mb-6 tracking-tight text-balance">
              Short links, big <span className="text-tower-600">impact</span>
            </h1>
            <p className="text-lg md:text-xl text-muted-foreground mb-12 max-w-2xl mx-auto text-balance">
              Create memorable, branded links that stand out and drive results.
              Powerful analytics to track your success.
            </p>

            <UrlShortener onUrlCreated={handleUrlCreated} />
            <UrlList newUrl={newUrl} />
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="container mx-auto px-6 py-24">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              All the tools you need
            </h2>
            <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
              towr.co gives you everything you need to shorten, manage, and
              track your links.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Link size={24} />}
              title="Custom Links"
              description="Create branded, memorable links that reflect your identity and are easy to share."
            />
            <FeatureCard
              icon={<BarChart size={24} />}
              title="Detailed Analytics"
              description="Track performance with real-time click data, geographic insights, and referrer tracking."
            />
            <FeatureCard
              icon={<Zap size={24} />}
              title="Lightning Fast"
              description="Our infrastructure ensures your links load quickly, providing a seamless experience."
            />
            <FeatureCard
              icon={<Globe size={24} />}
              title="Global Reach"
              description="Distributed network ensures your links work reliably across the globe."
            />
            <FeatureCard
              icon={<Lock size={24} />}
              title="Secure & Private"
              description="Advanced security features protect your links and data from threats."
            />
            <FeatureCard
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 12C14 14.2091 12.2091 16 10 16C7.79086 16 6 14.2091 6 12C6 9.79086 7.79086 8 10 8C12.2091 8 14 9.79086 14 12Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20 4V8"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20 16V20"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 6H22"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 18H22"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 20L8 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 10L18 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              title="Integrations"
              description="Integrate with your favorite apps and platforms through our powerful API."
            />
          </div>
        </section>

        {/* API Section */}
        <section
          id="api"
          className="bg-gradient-subtle from-tower-50 to-white py-24"
        >
          <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-col md:flex-row gap-12 items-center">
                <div className="flex-1">
                  <div className="inline-block px-3 py-1 mb-6 rounded-full bg-tower-100 text-tower-700 text-sm font-medium">
                    Developer API
                  </div>
                  <h2 className="text-3xl font-bold mb-6">
                    Power your apps with our API
                  </h2>
                  <p className="text-muted-foreground mb-8">
                    Our robust API allows you to integrate towr.co's powerful
                    link management capabilities directly into your applications
                    and workflows.
                  </p>
                  <ul className="space-y-4 mb-8">
                    <APIFeature>
                      Create and manage links programmatically
                    </APIFeature>
                    <APIFeature>Fetch detailed analytics data</APIFeature>
                    <APIFeature>Custom domain support</APIFeature>
                    <APIFeature>Webhook notifications</APIFeature>
                  </ul>
                  <a
                    href="#"
                    className="inline-flex items-center text-tower-600 font-medium hover:text-tower-700 transition-colors duration-200"
                  >
                    View API documentation
                    <ChevronRight size={18} className="ml-1" />
                  </a>
                </div>
                <div className="flex-1">
                  <div className="bg-white rounded-lg shadow-glass p-5 font-mono text-sm overflow-hidden">
                    <div className="flex items-center mb-4">
                      <div className="w-3 h-3 rounded-full bg-red-400 mr-2"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-400 mr-2"></div>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                    </div>
                    <pre className="text-xs md:text-sm text-gray-800 overflow-x-auto">
                      {`// Create a shortened URL
const response = await fetch('https://api.towr.co/shorten', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    url: 'https://example.com/very-long-url',
    custom_path: 'my-brand',
    domain: 'towr.co'
  })
});

const data = await response.json();
console.log(data.short_url); // https://towr.co/my-brand`}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="container mx-auto px-6 py-24">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Simple, transparent pricing
            </h2>
            <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
              Choose the plan that's right for you.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <PricingCard
              title="Free"
              price="€0"
              description="Perfect for individuals and small projects."
              features={[
                "10 links per month",
                "Basic analytics",
                "7-day history",
              ]}
              buttonText="Get Started"
              buttonVariant="outline"
            />
            <PricingCard
              title="Pro"
              price="€10"
              description="For growing businesses and content creators."
              features={[
                "1000 links per month",
                "Advanced analytics",
                "30-day history",
                "Custom domains",
                "API access (100 req/day)",
                "Priority support",
              ]}
              buttonText="Start Free Trial"
              buttonVariant="primary"
              popular
            />
            <PricingCard
              title="Enterprise"
              price="€49"
              description="For large organizations with advanced needs."
              features={[
                "Unlimited links",
                "Advanced analytics",
                "Unlimited history",
                "Multiple custom domains",
                "Unlimited API access",
                "24/7 priority support",
                "SSO and team management",
              ]}
              buttonText="Contact Sales"
              buttonVariant="outline"
            />
          </div>
        </section>

        {/* CTA Section */}
        <section className="container mx-auto px-6 py-16">
          <div className="max-w-4xl mx-auto glass-card p-12 text-center">
            <h2 className="text-3xl font-bold mb-6">
              Ready to simplify your links?
            </h2>
            <p className="text-lg text-muted-foreground mb-8 max-w-xl mx-auto">
              Join thousands of marketers, developers, and content creators who
              trust towr.co for their link management needs.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="px-8 py-3 rounded-full bg-tower-500 text-white hover:bg-tower-600 transition-all duration-300 shadow-subtle hover:shadow-md">
                Sign Up Free
              </button>
              <button className="px-8 py-3 rounded-full bg-white text-tower-700 hover:bg-gray-50 transition-all duration-300 shadow-subtle hover:shadow-md border border-gray-200">
                Schedule a Demo
              </button>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <div className="glass-card p-6 transition-all duration-300 hover:shadow-hover">
    <div className="w-12 h-12 rounded-lg bg-tower-100 text-tower-600 flex items-center justify-center mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-medium mb-3">{title}</h3>
    <p className="text-muted-foreground">{description}</p>
  </div>
);

interface APIFeatureProps {
  children: React.ReactNode;
}

const APIFeature = ({ children }: APIFeatureProps) => (
  <li className="flex items-center">
    <div className="w-5 h-5 rounded-full bg-tower-100 text-tower-600 flex items-center justify-center mr-3">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 3L4.5 8.5L2 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
    <span>{children}</span>
  </li>
);

interface PricingCardProps {
  title: string;
  price: string;
  description: string;
  features: string[];
  buttonText: string;
  buttonVariant: "outline" | "primary";
  popular?: boolean;
}

const PricingCard = ({
  title,
  price,
  description,
  features,
  buttonText,
  buttonVariant,
  popular = false,
}: PricingCardProps) => (
  <div
    className={`glass-card p-8 relative ${popular ? "border-tower-200 shadow-hover" : ""}`}
  >
    {popular && (
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-tower-500 text-white text-xs font-medium px-3 py-1 rounded-full">
        Most Popular
      </div>
    )}
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <div className="flex items-baseline mb-2">
      <span className="text-3xl font-bold">{price}</span>
      {price !== "Custom" && (
        <span className="text-muted-foreground ml-1">/month</span>
      )}
    </div>
    <p className="text-muted-foreground mb-6">{description}</p>
    <ul className="space-y-3 mb-8">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <svg
            className="w-5 h-5 text-tower-500 mr-2 mt-0.5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          <span className="text-sm">{feature}</span>
        </li>
      ))}
    </ul>
    <button
      className={`w-full py-2 rounded-full transition-all duration-300 ${
        buttonVariant === "primary"
          ? "bg-tower-500 text-white hover:bg-tower-600"
          : "bg-white border border-gray-200 hover:bg-gray-50 text-foreground"
      }`}
    >
      {buttonText}
    </button>
  </div>
);

export default Index;
