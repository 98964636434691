
import { useEffect, useState } from 'react';
import { getAnalytics, ClickAnalytics } from '@/lib/analytics';
import { Card, CardContent } from '@/components/ui/card';
import { PieChart, Pie, LineChart, Line, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer, BarChart, Bar } from 'recharts';

interface LinkAnalyticsProps {
  shortCode: string;
}

const COLORS = ['#0ea5e9', '#38bdf8', '#7dd3fc', '#bae6fd', '#e0f2fe'];

export const LinkAnalytics = ({ shortCode }: LinkAnalyticsProps) => {
  const [analytics, setAnalytics] = useState<ClickAnalytics | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const data = getAnalytics(shortCode);
      setAnalytics(data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setIsLoading(false);
    }
  }, [shortCode]);

  if (isLoading) {
    return (
      <div className="animate-pulse p-6">
        <div className="h-40 bg-gray-200 rounded-lg mb-4"></div>
        <div className="h-40 bg-gray-200 rounded-lg"></div>
      </div>
    );
  }

  if (!analytics || analytics.totalClicks === 0) {
    return (
      <div className="py-8 text-center">
        <h3 className="text-lg font-medium mb-2">No data yet</h3>
        <p className="text-muted-foreground">
          Analytics will appear here after your link gets clicked.
        </p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
        <StatCard title="Total Clicks" value={analytics.totalClicks} />
        <StatCard 
          title="Top Referrer" 
          value={analytics.referrers.length > 0 ? analytics.referrers[0].source : 'None'} 
        />
        <StatCard 
          title="Top Device" 
          value={analytics.devices.length > 0 ? analytics.devices[0].type : 'None'} 
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Clicks Over Time Chart */}
        <Card className="glass-card">
          <CardContent className="pt-6">
            <h3 className="text-sm font-medium mb-4">Clicks Over Time</h3>
            <div className="h-60">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analytics.clicksPerDay}>
                  <XAxis 
                    dataKey="date" 
                    tick={{ fontSize: 12 }} 
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis 
                    tick={{ fontSize: 12 }} 
                    tickLine={false}
                    axisLine={false}
                    allowDecimals={false}
                  />
                  <Tooltip 
                    contentStyle={{ 
                      borderRadius: '8px', 
                      border: 'none', 
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.95)'
                    }} 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="count" 
                    stroke="#0ea5e9" 
                    strokeWidth={2} 
                    activeDot={{ r: 6, fill: '#0ea5e9', stroke: 'white', strokeWidth: 2 }} 
                    dot={{ r: 4, fill: '#0ea5e9', stroke: 'white', strokeWidth: 2 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Browsers Chart */}
        <Card className="glass-card">
          <CardContent className="pt-6">
            <h3 className="text-sm font-medium mb-4">Browsers</h3>
            <div className="h-60">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={analytics.browsers}
                    nameKey="name"
                    dataKey="count"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={40}
                    paddingAngle={2}
                    label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                    labelLine={false}
                  >
                    {analytics.browsers.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value} clicks`, 'Count']}
                    contentStyle={{ 
                      borderRadius: '8px', 
                      border: 'none', 
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.95)'
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Referrers Chart */}
        <Card className="glass-card">
          <CardContent className="pt-6">
            <h3 className="text-sm font-medium mb-4">Referrers</h3>
            <div className="h-60">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={analytics.referrers.slice(0, 5)}>
                  <XAxis 
                    dataKey="source" 
                    tick={{ fontSize: 12 }} 
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis 
                    tick={{ fontSize: 12 }} 
                    tickLine={false}
                    axisLine={false}
                    allowDecimals={false}
                  />
                  <Tooltip 
                    formatter={(value) => [`${value} clicks`, 'Count']}
                    contentStyle={{ 
                      borderRadius: '8px', 
                      border: 'none', 
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.95)'
                    }}
                  />
                  <Bar dataKey="count" fill="#0ea5e9" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Devices Chart */}
        <Card className="glass-card">
          <CardContent className="pt-6">
            <h3 className="text-sm font-medium mb-4">Devices</h3>
            <div className="h-60">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={analytics.devices}
                    nameKey="type"
                    dataKey="count"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={40}
                    paddingAngle={2}
                    label={({ type, percent }) => `${type} (${(percent * 100).toFixed(0)}%)`}
                    labelLine={false}
                  >
                    {analytics.devices.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value} clicks`, 'Count']}
                    contentStyle={{ 
                      borderRadius: '8px', 
                      border: 'none', 
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.95)'
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

interface StatCardProps {
  title: string;
  value: string | number;
}

const StatCard = ({ title, value }: StatCardProps) => {
  return (
    <Card className="glass-card">
      <CardContent className="p-4 flex flex-col items-center justify-center text-center">
        <h3 className="text-sm font-medium text-muted-foreground">{title}</h3>
        <p className="text-2xl font-bold mt-2">{value}</p>
      </CardContent>
    </Card>
  );
};

export default LinkAnalytics;
