
import { ShortUrl } from './shortenerService';

export interface ClickAnalytics {
  totalClicks: number;
  clicksPerDay: { date: string; count: number }[];
  referrers: { source: string; count: number }[];
  devices: { type: string; count: number }[];
  browsers: { name: string; count: number }[];
}

interface ClickData {
  timestamp: string;
  referrer: string | null;
  userAgent: string;
}

// Helper to get a friendly date format
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

// Get browser name from user agent
const getBrowserName = (userAgent: string): string => {
  if (userAgent.includes('Firefox')) return 'Firefox';
  if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) return 'Chrome';
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) return 'Safari';
  if (userAgent.includes('Edg')) return 'Edge';
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) return 'Opera';
  return 'Other';
};

// Get device type from user agent
const getDeviceType = (userAgent: string): string => {
  if (userAgent.includes('Mobile')) return 'Mobile';
  if (userAgent.includes('Tablet')) return 'Tablet';
  return 'Desktop';
};

// Extract domain from referrer
const getReferrerDomain = (referrer: string | null): string => {
  if (!referrer) return 'Direct';
  try {
    const url = new URL(referrer);
    return url.hostname;
  } catch (e) {
    return 'Unknown';
  }
};

// Group and count items
const groupAndCount = <T>(items: T[], keyFn: (item: T) => string): { [key: string]: number } => {
  return items.reduce((acc, item) => {
    const key = keyFn(item);
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });
};

// Main function to generate analytics for a URL
export const getAnalytics = (shortCode: string): ClickAnalytics => {
  // Get click data from local storage
  const clicksStorageKey = `towrco_clicks_${shortCode}`;
  const storedClicks = localStorage.getItem(clicksStorageKey);
  const clicks: ClickData[] = storedClicks ? JSON.parse(storedClicks) : [];
  
  // Total clicks
  const totalClicks = clicks.length;
  
  // Clicks per day
  const clicksByDate = groupAndCount(clicks, (click) => 
    formatDate(click.timestamp)
  );
  
  const clicksPerDay = Object.entries(clicksByDate)
    .map(([date, count]) => ({ date, count }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
  // Referrers
  const referrerData = groupAndCount(clicks, (click) => 
    getReferrerDomain(click.referrer)
  );
  
  const referrers = Object.entries(referrerData)
    .map(([source, count]) => ({ source, count }))
    .sort((a, b) => b.count - a.count);
  
  // Devices
  const deviceData = groupAndCount(clicks, (click) => 
    getDeviceType(click.userAgent)
  );
  
  const devices = Object.entries(deviceData)
    .map(([type, count]) => ({ type, count }))
    .sort((a, b) => b.count - a.count);
  
  // Browsers
  const browserData = groupAndCount(clicks, (click) => 
    getBrowserName(click.userAgent)
  );
  
  const browsers = Object.entries(browserData)
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count);
  
  return {
    totalClicks,
    clicksPerDay,
    referrers,
    devices,
    browsers
  };
};
